// 遗产使用功能图
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    snTable: {
        table: {
            data: [],
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'QKSM',
                label: '情况说明',
            }, {
                prop: 'PG',
                label: '评估',
                transList: {
                    0: "正面影响",
                    1: "兼有正、负影响",
                    2: "负面影响",
                    3: "无影响"
                }
            }, {
                prop: 'JZT',
                label: '基准年份',
            }, {
                prop: 'DBT',
                label: '对比年份',
            },
            ],
            columnsBtn: {
                buttonData: []
            },
        },
    },
    snButton: {
        buttonData: [{
            btnType: 'button',
            operateType: 'toZtgjbhjlMap',
            name: '地图视图',
            round: true,
            backColor: '#28ccd9',
            color: '#fff'
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            showMethod: 'showExportBtn',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    },
    snSearch: {
        selectData: [
            // {
            //     "label": "评估",
            //     "clearable": true,
            //     "placeholder": "请选择评估",
            //     "list": [
            //         { "ID": "正面影响", "NAME": "正面影响" },
            //         { "ID": "兼有正、负影响", "NAME": "兼有正、负影响" },
            //         { "ID": "负面影响", "NAME": "负面影响" },
            //         { "ID": "无影响", "NAME": "无影响" }
            //     ],
            //     "optionLabel": "NAME",
            //     "optionValue": "ID",
            //     "value": "PG",
            //     "operateType": "search",
            //     "isShow": true
            // }
        ],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'searchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            }]
        }
    },
};
export default selfData;